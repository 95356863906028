import {ValueOf} from "../../../types";

export type ButtonVariant = ValueOf<typeof ButtonVariant>;
export const ButtonVariant = {
  DESTROY: "destroy",
  GHOST: "ghost",
  GREEN: "green",
  OUTLINE: "outline",
  FILL: "fill",
  WHITE: "white",
  SELECTED_FILL: "selectedFill",
  NAKED: "naked",
} as const;

export type ButtonSize = ValueOf<typeof ButtonSize>;
export const ButtonSize = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  JB: "jb",
} as const;

export type ButtonShape = ValueOf<typeof ButtonShape>;
export const ButtonShape = {
  ROUND: "round",
  CIRCLE: "circle",
} as const;
